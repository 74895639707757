<template>
  <div>
    <ui-header :heading="$tc('models.course', 2)">
      <ui-link class="add_new_button" type="text" :route="{ name: 'courses-create' }">{{
        `${$tc('actions.add_new', 2)} ${$tc('models.course')}`
      }}</ui-link>
    </ui-header>

    <course-list
      :courses="courses"
      @onPublish="handlePublish"
      @onShowLockModal="onShowLock"
      @onShowUnlockModal="onShowUnlock"
      v-loading="loading"
    >
    </course-list>

    <ui-modal
      :show="showLockModal"
      :text="$t('labels.course.lock')"
      @confirm="handleToggleLock"
      @cancel="onLockModalCancel"
    />
    <ui-modal
      :show="showUnlockModal"
      :text="$t('labels.course.unlock')"
      @confirm="handleToggleLock"
      @cancel="onUnlockModalCancel"
    />
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import UiModal from '@/components/ui/UiModal';
import CourseList from '../components/CourseList';
import { getCourses, publishCourse, toggleCourseLock } from '../api';
import {translate} from "@/i18n/Translator";

export default {
  data() {
    return {
      courses: [],
      loading: false,
      showLockModal: false,
      showUnlockModal: false,
      lockTarget: null
    };
  },

  components: {
    UiHeader,
    UiLink,
    CourseList,
    UiModal
  },

  methods: {
    async getCourses() {
      this.loading = true;
      const courses = await getCourses(this.$route.params.lang);
      this.courses = courses;
      this.loading = false;
    },

    onShowLock(course) {
      this.lockTarget = course;
      this.showLockModal = true;
    },

    onLockModalCancel() {
      this.showLockModal = false;
    },

    onShowUnlock(course) {
      this.lockTarget = course;
      this.showUnlockModal = true;
    },

    onUnlockModalCancel() {
      this.showUnlockModal = false;
    },

    async handleToggleLock() {
      const errorMsg = { message: this.$t('errors.general'), type: 'error' };
      if (this.lockTarget === null) {
        this.$message(errorMsg);
        return;
      }
      this.loading = true;
      try {
        const id = this.lockTarget.id;
        const state = await toggleCourseLock(id);
        this.loading = false;
        this.courses.find(c => c.id === id).locked = state;
        this.lockTarget = null;
        const msg = {
          message: this.$t('feedback.update_success'),
          type: 'success'
        };
        this.$message(msg);
        this.showLockModal = false;
        this.showUnlockModal = false;
      } catch (err) {
        this.loading = false;
        this.lockTarget = null;
        this.$message(errorMsg);
      }
    },

    async handlePublish(toPublish) {
      this.loading = true;
      try {
        const course = await publishCourse(toPublish.id);
        this.courses.map(c => {
          if (c.id === course.id) {
            c.published = course.published;
          }
        });
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    }
  },

  created() {
    this.getCourses();
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.a_course", link: "/app/courses/"}
    ]);
  },
};
</script>

<style scoped>
.add_new_button {
  background-color: white;
  border: solid 1px black;
  color: black;
}
</style>
