<template>
  <ui-list
    :heading="heading" 
    :headings="getHeadings()"
    :sort="[
      { prop: 'title', type: 'translation' },
      { prop: 'building', prop2: 'name', type: 'level2' },
      { prop: 'progress', prop2: 'progressPercentage', type: 'level2' }
    ]"
    :items="courses"
    :search_selectors="[
      {prop: 'title', type: 'translation'},       
      { prop: 'building', prop2: 'name', type: 'level2' }
    ]"
  >
    <template v-slot:default="props">
      <ui-list-data
        :text="getTranslation(props.item.title)"
        :infoHtml="getTranslation(props.item.description)"
      >
      </ui-list-data>

      <ui-list-data type="tag" :text="props.item.building.name"> </ui-list-data>

      <template v-if="building">
        <ui-list-data-progress
          v-if="typeof getPercentage(props.item) === 'number'"
          :percentage="getPercentage(props.item)"
          :progressDetails="props.item.progress"
        >
        </ui-list-data-progress>
        <ui-list-data
          v-if="getPercentage(props.item) === false"
          :text="$t('labels.course.no_users_assigned')"
        >
        </ui-list-data>
      </template>

      <ui-list-data small>
        <auth-checker :accessRoles="['SuperAdmin']">
          <ui-link
            v-if="!props.item.published"
            type="success"
            class="publish_button"
            size="small"
            uppercase
            :disabled="props.item.locked"
            @click="onPublish(props.item)"
            >{{ $t('actions.publish') }}</ui-link
          >
          <span v-if="props.item.published">{{
            $d(
              new Date(props.item.published),
              'short',
              $i18n.locale === 'no' ? 'no' : 'en'
            )
          }}</span>
          <ui-link
            class="ml-2"
            type="normal"
            size="small"
            uppercase
            :disabled="props.item.locked"
            @click="
              $router.push({
                name: 'courses-show',
                params: { id: props.item.id }
              })
            "
            >{{ $t('labels.course.edit.content') }}</ui-link
          >
          <ui-link
            class="ml-2"
            type="normal"
            size="small"
            uppercase
            :disabled="props.item.locked"
            @click="
              $router.push({
                name: 'courses-edit',
                params: { id: props.item.id }
              })
            "
            >{{ $t('labels.course.edit.head') }}</ui-link
          >

          <ui-link
            v-if="props.item.locked"
            class="ml-2 lock_button"
            type="secondary"
            size="small"
            uppercase
            @click="onUnlock(props.item)"
          >
            <Unlocked class="svg-icon" />
            {{ $t('actions.unlock') }}
          </ui-link>

          <ui-link
            v-if="!props.item.locked"
            class="ml-2 lock_button"
            type="secondary"
            size="small"
            uppercase
            @click="onLock(props.item)"
          >
            <Locked class="svg-icon" />
            {{ $t('actions.lock') }}
          </ui-link>
        </auth-checker>
      </ui-list-data>
    </template>
  </ui-list>
</template>

<script>
import UiLink from '@/components/ui/UiLink';
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';
import UiListDataProgress from '@/components/ui/UiListDataProgress';
import AuthChecker from '../../auth/components/AuthChecker';
import Locked from '@/assets/images/locked.svg';
import Unlocked from '@/assets/images/unlocked.svg';

export default {
  props: {
    heading: {
      type: String
    },
    courses: {
      type: Array,
      required: true
    },
    building: {
      type: Object,
      default: null
    }
  },

  components: {
    UiLink,
    UiList,
    UiListData,
    UiListDataProgress,
    AuthChecker,
    Locked,
    Unlocked
  },

  methods: {
    getHeadings() {
      const list = [this.$t('labels.course.name'), this.$tc('models.building'), 'search'];
      if (this.building) {
        list.push(this.$t('misc.completion'));
      }
      list.push('');
      return list;
    },

    onLock(course) {
      this.$emit('onShowLockModal', course);
    },

    onUnlock(course) {
      this.$emit('onShowUnlockModal', course);
    },

    getPercentage(item) {
      if (item.progress && item.progress.assignedCount) {
        return item.progress.progressPercentage;
      } else {
        if (item.assignedCount) {
          const per = Math.floor(
            (item.completedCount / item.assignedCount) * 100
          );
          return per;
        } else {
          return false;
        }
      }
    },

    getTranslation(transList) {
      const trans = transList.filter(t => t.lang === this.$i18n.locale);
      if (trans.length == 0) return '';
      else return trans[0].value;
    },

    onPublish(course) {
      this.$emit('onPublish', course);
    }
  }
};
</script>
<style scoped>
.svg-icon {
  margin-right: 0.25rem;
  height: 1rem;
  fill: black;
  display: inline-block;
  vertical-align: text-top;
}

.lock_button {
  outline: solid 1px black;
}

.publish_button {
  background-color: #00D895;
}
</style>
